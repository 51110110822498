import {
  Button,
  cn,
  DecorativeFormsRow,
  DecorativeRow,
  LandingTitle,
} from '@cointracker/styleguide';
import { Body, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { trackSignUpButtonClicked } from 'src/common/analytics';
import { PartnerLogo } from '../Partner/PartnerLogo';
import { useLandingCTAProps } from './utils.tsx';

const DEFAULT_TITLE = 'Crypto taxes done right';

interface LandingHeaderCopyProps {
  partnerSlug?: string;
  toggleModal?: () => void;
}

export const LandingHeaderCopy = (props: LandingHeaderCopyProps) => {
  const { partnerSlug, toggleModal } = props;
  const { headerCopyProps, getStartedUrl, buttonText } =
    useLandingCTAProps(partnerSlug);

  const {
    superTitle,
    title,
    subtitle,
    mobileSubtitle,
    discountText,
    extendSubtitle,
  } = headerCopyProps;

  const trackSignup = () => {
    trackSignUpButtonClicked(
      buttonText,
      'hero',
      title ?? DEFAULT_TITLE,
      title ?? DEFAULT_TITLE,
    );
  };

  const hasSubDiscountText = !!discountText?.length;
  return (
    <div className={cn('px-16 pb-40 pt-80 text-center md:px-32 md:pt-112')}>
      {partnerSlug && <PartnerLogo partnerSlug={partnerSlug} />}
      {superTitle ? (
        <>
          <Heading
            variant="h6"
            as="h1"
            className="mb-12 text-20 leading-130 md:mb-0"
          >
            {superTitle}
          </Heading>
          <Heading variant="h1" as="h2">
            {title || DEFAULT_TITLE}
          </Heading>
        </>
      ) : (
        <LandingTitle>{title || DEFAULT_TITLE}</LandingTitle>
      )}
      <Heading
        variant="h6"
        className="mx-auto mb-24 mt-12 max-w-[682px] text-pretty text-center data-[extend-subtitle]:max-w-[900px] md:mb-0"
        data-extend-subtitle={!!extendSubtitle}
      >
        <span
          className="data-[has-mobile-subtitle=true]:hidden md:inline-block data-[has-mobile-subtitle=true]:md:inline-block"
          data-has-mobile-subtitle={!!mobileSubtitle}
        >
          {subtitle}
        </span>
        <span className="inline-block md:hidden">{mobileSubtitle}</span>
      </Heading>
      {hasSubDiscountText && (
        <Body className="text-text-secondary" variant="body5">
          {discountText}
        </Body>
      )}
      <DecorativeRow partnerSlug={partnerSlug} className="mt-16 md:mt-24" />
      <DecorativeFormsRow />
      <Button
        className="mt-40 hidden md:inline-block"
        onClick={() => {
          trackSignup();
          if (toggleModal && partnerSlug === 'coinbaseone') {
            toggleModal();
          } else {
            window.location.href = getStartedUrl;
          }
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
};
