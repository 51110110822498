import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { forwardRef } from 'react';
import { CallToActionButton } from '../LandingCTAs';
import { useLandingCTAProps } from './utils';

interface FixedBottomCTAProps {
  partnerSlug?: string;
  toggleModal?: () => void;
}

export const FixedBottomCTA = forwardRef<HTMLDivElement, FixedBottomCTAProps>(
  function FixedBottomCTA(
    { partnerSlug, toggleModal }: FixedBottomCTAProps,
    ref,
  ) {
    const { headerCopyProps, getStartedUrl, buttonText } =
      useLandingCTAProps(partnerSlug);
    const offerText = partnerSlug ? headerCopyProps.title : null;

    return (
      <div
        ref={ref}
        className="fixed bottom-0 left-0 right-0 z-50 flex flex-col items-center gap-10 bg-background-default p-16 shadow-elevation-1 md:hidden"
      >
        <CallToActionButton
          href="#"
          buttonText={buttonText}
          pageTitle="consumer landing"
          sectionName="fixed-bottom-cta"
          sectionTitle="bottom-cta"
          className="mt-0 w-full"
          overrideHrefHandler={() => {
            if (toggleModal && partnerSlug === 'coinbaseone') {
              toggleModal();
            } else {
              window.location.href = getStartedUrl;
            }
          }}
        >
          {buttonText}
        </CallToActionButton>
        {offerText && (
          <Body variant="body2" className="text-text-secondary">
            {offerText}
          </Body>
        )}
      </div>
    );
  },
);
